import { backgroundLogin } from 'assets/images/illustrations';
import { ReactNode } from 'react';

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div
      className="2xl:px-0 xl:px-32 back flex flex-col object-cover w-screen h-screen min-w-full min-h-full px-64 bg-cover"
      style={{
        backgroundImage: `url(${backgroundLogin})`,
      }}
    >
      <div className="flex items-center justify-center w-1/2 h-full">
        {children}
      </div>
      <div className="w-1/2" />
    </div>
  );
};

export { AuthLayout }
