import { useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Button } from 'components/Button';
import { TextField } from 'components/Inputs/TextField';
import { PasswordField } from 'components/Inputs/PasswordField';
import { Typography } from 'components/Typography';
import { logoGuio } from 'assets/images/illustrations';
import { Field, Form, Formik } from 'formik';
import { yupValidations } from 'utils/validations/yup';
import type { AuthData } from 'types/auth';

interface ILoginForm {
  handleSubmit(values: AuthData): void;
  loading: boolean;
}

export const LoginForm = ({ handleSubmit, loading }: ILoginForm) => {
  const router = useRouter();

  const getSchemaValidation = useCallback(() => {
    return Yup.object().shape({
      email: yupValidations.requiredEmail,
      password: yupValidations.requiredPassword,
    });
  }, []);

  useEffect(() => {
    router.prefetch('/calls');
  }, [router]);

  return (
    <div data-testid="login-form">
      <img src={logoGuio} alt="Logotipo Guio" className="mb-14" />
      <Formik
        onSubmit={handleSubmit}
        isInitialValid={false}
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={getSchemaValidation()}
      >
        {({ isValid }) => (
          <Form className="flex flex-col h-full space-y-9">
            <Typography variant="h2" color="darkBlue">
              Login
            </Typography>
            <Field
              component={TextField}
              label="E-mail"
              name="email"
              placeholder="Seu e-mail"
              data-testid="login-form-input-email"
            />
            <Field
              component={PasswordField}
              label="Senha"
              name="password"
              placeholder="Sua senha"
              data-testid="login-form-input-password"
            />
            <Link href="/password-recovery">
              <a className="flex flex-row-reverse">
                <Typography
                  color="darkerGray"
                  variant="p2"
                  className="-mt-5 underline cursor-pointer"
                >
                  Esqueci minha senha
                </Typography>
              </a>
            </Link>
            <Button
              size="large"
              type="submit"
              width={292}
              loading={loading}
              disabled={!isValid}
            >
              Entrar
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
