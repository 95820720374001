import { inactiveCancel } from 'assets/images/icons';
import { LoginForm } from 'features/Auth/components/LoginForm';
import { useUI } from 'hooks/useUI';
import { useRouter } from 'next/router';
import { useState, useCallback } from 'react';
import { Auth, setToken } from 'services/auth';
import { AuthData } from 'types/auth';

export const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const UI = useUI();
  const router = useRouter();

  const handleSubmit = useCallback(
    async ({ email, password }: AuthData) => {
      try {
        setLoading(true);
        const res = await Auth.login({
          email: email.toLowerCase().trim(),
          password,
        });
        if (res.status === 200) {
          setToken(res.data.access_token, res.data.refresh_token);
          router.push('/calls');
        }
      } catch (error) {
        switch (error.response?.status) {
          case 401:
            UI.operations.showToast(error.response.data?.message, {
              type: 'error',
              icon: inactiveCancel,
            });
            break;
          default:
            UI.operations.showToast('Usuário ou email incorretos.', {
              type: 'error',
              icon: inactiveCancel,
            });
            break;
        }
      } finally {
        setLoading(false);
      }
    },
    [UI, router]
  );

  return <LoginForm handleSubmit={handleSubmit} loading={loading} />;
};
