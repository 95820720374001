import styled from 'styled-components';

interface InputProps {
  invalid: boolean;
}

interface AdornmentProps {
  width?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

export const InputContainer = styled.div`
  display: inline;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.orangeClick};
  font-family: Asap;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-family: Asap;
  font-size: 14px;
  font-weight: 500;
`;

export const Input = styled.input<InputProps>`
  color: ${({ theme }) => theme.colors.darkBlue};
  border: 2px solid
    ${({ invalid, theme }) =>
      invalid ? theme.colors.orangeClick : theme.colors.gray};
  border-radius: 16px;
  font-family: Asap;
  font-size: 14px;
  font-weight: 500;
  height: ${({ height }) => (height ? height : '48px')};
  padding-left: 16px;
  padding-right: 54px;
  width: ${({ width }) => (width ? width : '292px')};

  :focus {
    border: 2px solid
      ${({ invalid, theme }) =>
        invalid ? theme.colors.orangeClick : theme.colors.purple};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Adornment = styled.img<AdornmentProps>`
  position: absolute;
  border: none;
  width: 16px;
  height: 16px;
  bottom: 18px;
  left: ${({ width }) =>
    width
      ? `calc(${width}px - ${width * 0.15}px)`
      : `calc(${292}px - ${292 * 0.14}px)`};
  &:hover {
    transform: scale(1.15);
    fill: brightness(1);
    cursor: pointer;
  }
`;
