import React, { useState } from 'react';
import {
  Container,
  ErrorMessage,
  Input,
  Label,
  Adornment,
  InputContainer,
} from './styles';
import { passwordHide, passwordShow } from 'assets/images/icons';

type FieldTypes = {
  name: string;
  onBlur: () => void;
  onChange: () => void;
  value: string;
};

type FormTypes = {
  touched: {
    [key: string]: boolean;
  };
  errors: {
    [key: string]: string;
  };
};

interface PasswordFieldProps {
  errorMessage?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  value?: number | string;
  onChange?: (e) => void;
  width?: number | string;
  height?: number | string;
  field?: FieldTypes;
  form?: FormTypes;
  disabled?: boolean;
  showPassword?: boolean;
}

const PasswordField = ({
  errorMessage,
  label,
  name,
  placeholder,
  value,
  onChange,
  width,
  height,
  field,
  form,
  disabled,
  showPassword,
}: PasswordFieldProps) => {
  let handleBlur = null;

  const [showPasswordState, setShowPasswordState] = useState(showPassword);

  const handleIcon = () => {
    if (showPasswordState) {
      return (
        <Adornment
          src={passwordHide}
          onClick={() => setShowPasswordState(!showPasswordState)}
        />
      );
    } else {
      return (
        <Adornment
          src={passwordShow}
          onClick={() => setShowPasswordState(!showPasswordState)}
        />
      );
    }
  };

  if (field) {
    value = field.value;
    handleBlur = field.onBlur;
    name = field.name;

    if (!onChange) {
      onChange = field.onChange;
    }

    if (form.touched[field.name]) {
      errorMessage = form.errors[field.name];
    }
  }

  return (
    <Container>
      <Label htmlFor={label}>{label}</Label>
      <InputContainer>
        <Input
          id={label}
          name={name}
          type={showPasswordState ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          invalid={!!errorMessage}
          onChange={onChange}
          width={width}
          height={height}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {handleIcon()}
      </InputContainer>
      <div>
        <ErrorMessage hidden={!errorMessage}>{errorMessage}</ErrorMessage>
      </div>
    </Container>
  );
};

export { PasswordField };
