import { AuthLayout } from 'components/Layouts/AuthLayout';
import { LoginScreen } from 'features/Auth/AuthScreens/LoginScreen';
import { GetServerSideProps } from 'next';
import { GUIO_AUTH_TOKEN } from '../services/auth';
import { parseJwtSSR } from '../utils/parseJwt';
import { usePermissions as getUserPermissions } from '../hooks/permissions/usePermissions';

function PageLogin() {
  return <LoginScreen />;
}

PageLogin.Layout = AuthLayout;

export default PageLogin;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const token = context.req.cookies[GUIO_AUTH_TOKEN];

  if (!token) {
    return {
      props: {},
    };
  }

  const user = parseJwtSSR(token);
  const permissions = getUserPermissions(user);

  if (permissions.pathsAvailable.length === 0) {
    return {
      props: {},
    };
  }

  return {
    redirect: {
      destination: permissions.pathsAvailable[0],
      permanent: false,
    },
  };
};
